<template>
  <div id="app" v-loading.fullscreen.lock="$store.state.fullscreenLoading">
    <transition name="fade-transform" mode="out-in">
      <router-view v-wechat-title='$route.meta.title'/>
    </transition>
  </div>
</template>
<script>
import dynamicRouters from "@/router/getRouter";

export default {
  data() {
    return {
      get_notice_msg_time: null,
    }
  },
  created() {
    // this.getOption()  //获取配置信息

    if (this.$route.path !== '/login') {
      if (this.$store.state.token) {
        this.getUserInfo()
        dynamicRouters()   //  如果以登录状态获取菜单
        // this.getNoticeMsg()  //获取我的备忘预警

      } else {
        this.openWin('/login')
      }
    }

    setTimeout(() => {
      this.$store.commit("setAppFirst", false)
    }, 5000)


  },

  methods: {

    // getOption() {
    //   this.$api.post('/api/demo/config').then((res) => {
    //     const params = {
    //       domain_jqr: res.data.domain_jqr,
    //       domain_zdp: res.data.domain_zdp,
    //     }
    //     console.log("配置接口", params)
    //     this.$store.commit('saveOption', params)
    //   }).catch((err) => {
    //     console.log(1111, err)
    //   })
    // },
  }
}

</script>

<style lang="scss">
@import "assets/font/iconfont.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.input:focus {
  outline: none;
}


.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #2C3450 !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #2C3450 !important;
  color: #ffffff !important;
}

.dialog-view .el-dialog__footer {
  background: #ECF0F1;
  padding: 20px;

}

.upload-demo .el-upload {
  width: 100%;
  height: 100%;
}

.el-submenu__title {
  padding: 0 !important;
  line-height: 46px !important;
  height: 46px !important;

  .item {
    border-radius: 10px;
    padding: 0 15px;
  }
}

.el-menu-item-group > ul > li:hover, .el-submenu__title:hover {
  background: rgba(#883231, .08) !important;
}


.el-menu-item {
  background: #ffffff !important;
  height: 46px !important;

  .item {
    border-radius: 10px;
    padding: 0 15px;
    color: #888888;
    line-height: 46px !important;
  }
}

.el-menu-item-group .el-menu-item .item {
  padding-left: 36px !important;
}

.el-menu-item.is-active .item {
  color: #fff;
  border-radius: 6px;
  padding: 0 15px;
  background: #0052D9;
  border-radius: 4px;

  .iconfont {
    color: #fff !important;
  }


}

// .el-menu .is-active .el-submenu__title {
//   background: rgba(#883231, .08) !important;

//   .iconfont {
//     color: #E6442F !important;
//   }

//   span {
//     color: #E6442F !important;
//   }
// }

.line-01 {
  display: -webkit-box;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1; /*两行*/
  overflow: hidden;
}

.line-02 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /*两行*/
  overflow: hidden;
}

.text-orange {
  color: #FF8813;
}

.text-red {
  color: #FD1E3D;
}

.text-green {
  color: #0CC295;
}

.el-menu-item-group__title {
  padding: 0px !important;
}


body::-webkit-scrollbar {
  position: absolute;
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

body::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: #ffffff;
}

.el-table th.el-table__cell {
  background-color: #F8F9FC !important;
}

.page-height::-webkit-scrollbar {
  position: absolute;
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  background: rgb(0 0 0 / 50%);

}

.page-height::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: #ffffff;
}

.el-aside::-webkit-scrollbar {
  position: absolute;
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.el-aside::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: #ffffff;
}

.ql-container {
  height: 350px !important;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  /* max-width: 50%; */
}

.el-carousel__arrow--left {
  display: none !important;
}

.el-carousel__arrow--right {
  display: none !important;
}

.el-submenu .el-menu-item {
  padding: 0;
}

.el-menu-item {
  padding: 0 !important;
}

.el-submenu .el-menu-item {
  min-width: auto !important;
}

.el-menu-item-group {
  .el-menu-item .item {
    padding-left: 40px;
  }
}

.html-content img {
  max-width: 100%;
}

.notice-detail .el-dialog {
  margin-top: 10vh !important;
}

.pagination-view {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}


.table-btn-group {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  margin-top: 10px;
}


//.el-table__body-wrapper {
//  overflow: overlay;
//}
//
//.el-table__body-wrapper::-webkit-scrollbar {
//  position: absolute;
//  /*滚动条整体样式*/
//  width: 18px; /*高宽分别对应横竖滚动条的尺寸*/
//  height: 8px;
//  cursor: pointer;
//  background: #f8f9fc;
//
//}
//
//.el-table__body-wrapper::-webkit-scrollbar-thumb {
//  /*滚动条里面小方块*/
//  border-radius: 0px;
//  background: rgb(0 0 0 / 40%);
//}

.el-dialog__wrapper {
  overflow: overlay;
}

.el-dialog__wrapper::-webkit-scrollbar {
  position: absolute;
  /*滚动条整体样式*/
  width: 12px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  background: #f9fafd;

}

.el-dialog__wrapper::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background: #959698;
}

.home-page {
  background: #ffffff;
  border-radius: 6px;
  padding: 26px 0;
  padding-bottom: 20px;
}

.home-page.seach {
  padding-bottom: 4px;
}

.seach {
  border-bottom: 1px solid #E7E7E7;
  margin-bottom: 20px;
  .el-date-editor .el-range-separator {
    width: 8% !important;
  }

  .el-date-editor .el-range-input {
    width: 40%;
  }

  .el-input__inner {
    width: 260px !important;
    // height: 40px !important;
  }

  .ttt .el-form-item__label {
    line-height: 20px;
  }
}


.el-table .el-table__header .el-table__cell {
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.4) !important;
  font-weight: 500 !important;
}
.el-table__row:nth-child(2n) .el-table__cell:first-child{
  background-color: #fff !important;
}
.el-table__row:nth-child(2n) .el-table__cell {
  background-color: #F3F3F3 !important;
}

// .el-table__row:nth-child(2n) .el-table__cell:first-child{
//   background-color: #fff !important;
// }
.el-table__cell {
  // padding: 7px 0 !important;
}

.el-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.el-pagination__total {
  position: absolute;
  left: 30px;
}

.el-pagination .number {
  border: 1px solid #eee;
  border-radius: 5px;
  min-width: 30px;
  margin: 0 4px;
  // padding: 5px;
}

.el-pagination .active {
  background-color: #0052D9;
  color: #fff !important;
}

.el-drawer__header {
  border-bottom: 1px solid #eee !important;
  margin-bottom: 0;
  padding-bottom: 20px !important;
}

.el-upload--picture-card {
  width: 100px !important;
  height: 100px !important;
  line-height: 98px !important;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 100px !important;
  height: 100px !important;
  // line-height: 98px !important;
}

.el-drawer__header {
  margin-bottom: 0px !important;
}

.html {
  img {
    max-width: 100%;
  }
}

.el-cascader__tags .el-tag {
  color: #333333 !important;
}
.plsc{
  background-color: #fff !important; color: red !important;border:1px solid red !important
}
.jy_plsc{
  background-color: #eee !important; color: #aaa !important;border:1px solid #eee !important
}
.ddddssssrt{
  background-color: #fff !important; color: #67C23A !important;border:1px solid #67C23A !important
}
.el-dialog__header{
  border-bottom: 1px solid #eee;
}
.el-dialog{
  border-radius: 10px !important;
}
.el-dialog__body{
  padding: 20px !important;
}
.el-pagination{
  padding-right: 30px !important;
}

.dsfcxcv .el-table__row:nth-child(2n) .el-table__cell:first-child{
  background-color: #F3F3F3 !important;
}
.el-dialog__title{
  font-weight: 600;
}
.el-form-item__label{
  font-weight: 600;
}

.el-tooltip__popper.is-light{
  border: 0 !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  max-width: 200px;
  word-break:break-all
}
.el-tooltip__popper.is-light[x-placement^=bottom] .popper__arrow::after{
  display: none;
}
.el-tooltip__popper .popper__arrow, .el-tooltip__popper .popper__arrow::after{
  opacity: 0;
}
.dsfcxcv{
        .el-table--border, .el-table--group{
            border-top: 0;
        }
        .cell{
            padding: 0 15px;
        }
        .ditt{
            padding: 0 !important;
            .cell{
                padding: 0;
            }
        }
    }
    .el-dialog{
      margin-top:11vh !important
    }
</style>
